import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { Content } from 'src/app/shared/Content';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-marketingcontent',
  templateUrl: './marketingcontent.component.html',
  styleUrls: ['./marketingcontent.component.css']
})
export class MarketingcontentComponent implements OnInit {

  contents: any;
  terms: any;
  cont: any;
  config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    currentTotalItems: 0
  };
  dbFieldName: string;
  dbFieldType: string;
  searchText = '';
  submitted: boolean=false;
  constructor(private sharedService: SharedService, private router: Router,private toastrService: ToastService) { }

  ngOnInit() {
    this.Getcontent();
  }
  createContent(){
    const content =new Content;
    this.sharedService.contentSubject.next(content);
     this.router.navigate([`user/editor`]);
  }
  editContent(PageContent:Content)
  {
    this.sharedService.contentSubject.next(PageContent);
     this.router.navigate([`user/editor`]);
  }
  deleteContent(PageContent:Content)
  {
    this.submitted = true;
    this.sharedService.DeleteContent(PageContent).subscribe((data: any) => {
      this.toastrService.success("Content deleted Successfully.!!");
      this.Getcontent();
      this.router.navigate(['user/marketing']);
      this.submitted = false;
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  Getcontent() {
    this.sharedService.Getcontent().subscribe((data: any) => {
      this.contents = data;
      this.config.totalItems = this.contents.length;
    })
  }
  GetcontentById(id: number) {
    this.router.navigate(['user/details'], { queryParams: { Id: id } });
  }
  showPages(page) {
    this.config.currentPage=1;
    if (page === 'All') {
      this.config.itemsPerPage = this.config.currentTotalItems;
    } else {
      this.config.itemsPerPage = page;
    }
  }

  onPageChange(event) {
    this.config.currentPage = event;
  }
  sort(dbField, dbType) {
  
  }
}  
