import { Component, OnInit } from '@angular/core';
import { Content } from 'src/app/shared/Content';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {

  tutorials: any;
  terms: any;
  cont: any;
  config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    currentTotalItems: 0
  };
  dbFieldName: string;
  dbFieldType: string;
  searchText = '';
  submitted: boolean=false;
  constructor(private sharedService: SharedService, private router: Router,private toastrService: ToastService) { }

  ngOnInit() {
    this.GetTutorial();
  }
  createTutorial(){
    const content =new Content;
    this.sharedService.contentSubject.next(content);
     this.router.navigate([`user/teditor`]);
  }
  editTutorial(PageContent:Content)
  {
    this.sharedService.contentSubject.next(PageContent);
     this.router.navigate([`user/teditor`]);
  }
  deleteTutorial(PageContent:Content)
  {
    this.submitted = true;
    this.sharedService.DeleteTutorial(PageContent).subscribe((data: any) => {
      this.toastrService.success("Tutorial deleted Successfully.!!");
      this.GetTutorial();
      this.router.navigate(['user/tutorial']);
      this.submitted = false;
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  GetTutorial() {
    this.sharedService.GetTutorials().subscribe((data: any) => {
      this.tutorials = data;
      this.config.totalItems = this.tutorials.length;
    })
  }
  GetcontentById(id: number) {
    this.router.navigate(['user/details'], { queryParams: { Id: id } });
  }
  showPages(page) {
    this.config.currentPage=1;
    if (page === 'All') {
      this.config.itemsPerPage = this.config.currentTotalItems;
    } else {
      this.config.itemsPerPage = page;
    }
  }

  onPageChange(event) {
    this.config.currentPage = event;
  }
  sort(dbField, dbType) {
  
  }
}  
