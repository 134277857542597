import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Content } from '../shared/Content';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }
  url: any;
  ContentData = {
    Id: null,
    Title: '',
    Content: ''
  }
  // for content update
  contentSubject: any = new BehaviorSubject<any>(this.ContentData);
  contentObservabel$ = this.contentSubject.asObservable();

  checkLogin(body) {
    return this.http.post(environment.ServerURL + '/ProfitUserAccount/Signin', body);
  }

  GetActiveUsers() {
    return this.http.get(environment.ServerURL + '/ProfitUserAccount/GetActiveUsers');
  }
  GetActiveNotifications() {
    return this.http.get(environment.ServerURL + 'api/Notification/GetFCMActiveNotifications');
  }

  SendNotifications(Notification: any) {
    return this.http.post(environment.ServerURL + `api/Notification/SendFCMNotifications`, Notification);
  }
  UpdateFCMNotification(body) {
    return this.http.put(environment.ServerURL + 'api/Notification/UpdateFCMNotifications', body);
  }
  CreateNotifications(Notification: any) {
    return this.http.post(environment.ServerURL + `api/Notification/CreateFCMNotifications`, Notification);
  }

  AddUpdateContent(pagecontent) {

    return this.http.post(environment.ServerURL + `api/Marketing/CreateMarketingContent`, pagecontent);
  }
  Getcontent() {
    return this.http.get(environment.ServerURL + `api/Marketing/GetAllMarketingContent`);
  }
  GetcontentById(Id) {

    return this.http.get(environment.ServerURL + `api/Marketing/GetpagecontentBy?Id=` + Id);
  }
  DeleteContent(pagecontent) {

    return this.http.put(environment.ServerURL + `api/Marketing/DeleteMarketingContent`, pagecontent);
  }
  AddUpdateTutorial(pagecontent) {

    return this.http.post(environment.ServerURL + `api/Marketing/CreateTutorial`, pagecontent);
  }
  GetTutorials() {
    return this.http.get(environment.ServerURL + `api/Marketing/GetAllTutorials`);
  }
  GetTutorialById(Id) {

    return this.http.get(environment.ServerURL + `api/Marketing/GetTutorialBy?Id=` + Id);
  }
  DeleteTutorial(pagecontent) {

    return this.http.put(environment.ServerURL + `api/Marketing/DeleteTutorial`, pagecontent);
  }
  GetAllUserScanHistory() {
    return this.http.get(environment.ServerURL + '/ProfitUserAccount/GetAllUserScanHistory');
  }
}
