import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-scanhistory',
  templateUrl: './scanhistory.component.html',
  styleUrls: ['./scanhistory.component.css']
})
export class ScanhistoryComponent implements OnInit {
  scanHistoryData: any;
  config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    currentTotalItems: 0
  };
  dbFieldName: string;
  dbFieldType: string;
  //dtOptions: DataTables.Settings = {};
  searchText = '';
  isDesc: boolean;
  isDescActualProfit: boolean;
  p: number = 1;
  constructor(private sharedService: SharedService, private route: ActivatedRoute,
    private router: Router, private toastrService: ToastService) { }

  ngOnInit(): void {
    this.getScanHistory();
  }
  getScanHistory() {
    this.sharedService.GetAllUserScanHistory().subscribe((response: any) => {
      if  (response!=null){
      this.scanHistoryData = response;
      this.config.totalItems = this.scanHistoryData.length;
      }
    });
  }
  showPages(page) {
    this.config.currentPage=1;
    if (page === 'All') {
      this.config.itemsPerPage = this.config.currentTotalItems;
    } else {
      this.config.itemsPerPage = page;
    }
  }

  onPageChange(event) {
    this.config.currentPage = event;
  }
  sort(dbField, dbType) {
    if (dbField == 'ActualProfit') {
      this.isDescActualProfit = !this.isDescActualProfit;
    }
    this.isDesc = !this.isDesc;
    this.dbFieldName = dbField;
    this.dbFieldType = dbType;
  }
}
