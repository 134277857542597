import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantvariablesService {

  public  itemnew: any;
  public  oldsearchstring: any = '';

  constructor() { }
}
