<div class="container-fluid">
  <div class="col-sm-12" style="margin: 72px 0 0 0;font-size: 32px;padding: 15px 0;">
    <b>Notifications</b>

</div> 
  <mdb-card>
    <div class="card-header">
      <b> All User Topic</b>

      <a class="btn btn-sm float-right"
        style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
        (click)="CreateNotificationModel()">
        Create Notification</a>
    </div>
    <mdb-card-body>
      <div class="table-responsive" *ngIf="AllNotifications">
        <table class="table table-borderless">
          <thead style="font-size: 12px;">
            <tr class="d-flex">
              <!-- <th class="col-md-1 d-none d-sm-block d-sm-none d-md-block"></th> -->
              <th class="col-md-1 col-sm-2 col-4">Title</th>
              <th class="col-md-4 col-sm-2 col-6">Notification Text</th>
              <th class="col-md-2 col-sm-2 col-4">Created On</th>
              <th class="col-md-2 col-sm-2 col-6">Status</th>
              <th class="col-md-2 col-sm-2 col-2">Action</th>
            </tr>
          </thead>
          <tbody style="font-size: 12px;">
            <tr class="d-flex align-items-center" *ngFor="let notification of AllNotifications;let i = index">

              <td class="col-md-1 col-sm-2 col-4">{{notification.Title}}</td>
              <td class="col-md-4 col-sm-2 col-6">{{notification.Text}}</td>
              <td class="col-md-2 col-sm-2 col-6">{{notification.CreatedOn| date:'shortDate'}}</td>
              <td class="col-md-2 col-sm-2 col-4" *ngIf="notification.Active==true">Active</td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="SendNotification(notification)">
                  Send Notification</a>
              </td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="UpdateNotifications(notification)">
                  Deactivate</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="AllNotifications?.length==0">
        <p class="card-header">Notifications not available</p>
      </div>
    </mdb-card-body>
  </mdb-card>

  <mdb-card>
    <div class="card-header">
      <b>Paid User Topic</b>
    </div>
    <mdb-card-body>
      <div class="table-responsive" *ngIf="PaidNotifications">
        <table class="table table-borderless">
          <thead style="font-size: 12px;">
            <tr class="d-flex">
              <!-- <th class="col-md-1 d-none d-sm-block d-sm-none d-md-block"></th> -->
              <th class="col-md-1 col-sm-2 col-4">Title</th>
              <th class="col-md-4 col-sm-2 col-6">Notification Text</th>
              <th class="col-md-2 col-sm-2 col-4">Created On</th>
              <th class="col-md-2 col-sm-2 col-6">Status</th>
              <th class="col-md-2 col-sm-2 col-2">Action</th>
              <th class="col-md-2 col-sm-2 col-2"></th>
            </tr>
          </thead>
          <tbody style="font-size: 12px;">
            <tr class="d-flex align-items-center" *ngFor="let notification of PaidNotifications;let i = index">

              <td class="col-md-1 col-sm-2 col-4">{{notification.Title}}</td>
              <td class="col-md-4 col-sm-2 col-6">{{notification.Text}}</td>
              <td class="col-md-2 col-sm-2 col-6">{{notification.CreatedOn| date:'shortDate'}}</td>
              <td class="col-md-2 col-sm-2 col-4" *ngIf="notification.Active==true">Active</td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="SendNotification(notification)">
                  Send Notification</a>
              </td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="UpdateNotifications(notification)">
                  Deactivate</a>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="PaidNotifications.length==0">
        <p class="card-header">Notifications not available</p>
      </div>
    </mdb-card-body>
  </mdb-card>

  <mdb-card>
    <div class="card-header">
      <b>Free User Topic</b>
    </div>
    <mdb-card-body>
      <div class="table-responsive" *ngIf="FreeNotifications">
        <table class="table table-borderless">
          <thead style="font-size: 12px;">
            <tr class="d-flex">
              <!-- <th class="col-md-1 d-none d-sm-block d-sm-none d-md-block"></th> -->
              <th class="col-md-1 col-sm-2 col-4">Title</th>
              <th class="col-md-4 col-sm-2 col-6">Notification Text</th>
              <th class="col-md-2 col-sm-2 col-4">Created On</th>
              <th class="col-md-2 col-sm-2 col-6">Status</th>
              <th class="col-md-2 col-sm-2 col-2">Action</th>
            </tr>
          </thead>
          <tbody style="font-size: 12px;">
            <tr class="d-flex align-items-center" *ngFor="let notification of FreeNotifications;let i = index">

              <td class="col-md-1 col-sm-2 col-4">{{notification.Title}}</td>
              <td class="col-md-4 col-sm-2 col-6">{{notification.Text}}</td>
              <td class="col-md-2 col-sm-2 col-6">{{notification.CreatedOn| date:'shortDate'}}</td>
              <td class="col-md-2 col-sm-2 col-4" *ngIf="notification.Active==true">Active</td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="SendNotification(notification)">
                  Send Notification</a>
              </td>
              <td class="col-md-2 col-sm-2 col-2">
                <a class="btn btn-sm"
                  style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 10px;margin: 10px 5px;"
                  (click)="UpdateNotifications(notification)">
                  Deactivate</a>
              </td>

            </tr>
          </tbody>
        </table>
      </div>

      <div *ngIf="FreeNotifications.length==0">
        <p class="card-header">Notifications not available</p>
      </div>
    </mdb-card-body>
  </mdb-card>
</div>


<ng-template #loader>
  <p class="text-center"> Loading wait...</p>
</ng-template>



<div mdbModal #createModal="mdbModal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-size" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <img src="../../../assets/images/profit_scan_banner.png" alt="" class="img-fluid" style="height: 60px;">
        <button type="button" class="close pull-right" aria-label="Close" (click)="createModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="title-modal d-flex justify-content-center text-center">
        <h5 class="modal-title w-100">Create Notification</h5>
      </div>
      <div class="modal-body">
        <div class="row">
          <form [formGroup]="createForm" (ngSubmit)="onSubmit()" style="padding: 0 10px 10px 10px;">
            <div class="row">
             
                <div class="col-md-12 form-group">
                  <label class="placeholderSize">Select Topic
                    <span class="validatered"> *</span>
                  </label>
                  <ng-select formControlName="topicList" (change)="selectedTopicValues($event)"
                  (clear)="clearedSearchValuesSelection()" [items]="topicItems" bindLabel="topicName"
                  placeholder="Select Topic">
                </ng-select>
                    <!-- <ng-select formControlName="topicList" [items]="topicItems" [multiple]="false"
                      bindLabel="topicName" [closeOnSelect]="true" (change)="selectedTopicValues($event)" (clear)="clearedSearchValuesSelection()">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                          [ngModelOptions]="{ standalone : true }" /> {{item.Name}}
                      </ng-template>
                    </ng-select> -->
                  <!-- </ng-select> -->
    
                  <span *ngIf="submitted && createFormControl.topicList.errors" class="invalid-feedback">
                    <p *ngIf="createFormControl.topicList.errors.required">Topic is required</p>
                  </span>
                </div>
                <div class="col-sm-6">
                <div class="form-group">
                  <label class="field-label" for="notificationTitle">Title *</label>
                  <input type="text" class="form-control form-control-sm field-label" id="notificationTitle"
                    placeholder="Enter Title" formControlName="notificationTitle">
                  <span class="text-danger"
                    *ngIf="(createFormControl.notificationTitle.touched || submitted) && createFormControl.notificationTitle.errors?.required">
                    Title is required
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="field-label" for="notificationText">Text *</label>
                  <input type="text" class="form-control form-control-sm field-label" id="notificationText"
                    placeholder="Enter Text" formControlName="notificationText">
                  <span class="text-danger"
                    *ngIf="(createFormControl.notificationText.touched || submitted) && createFormControl.notificationText.errors?.required">
                    Text is required
                  </span>
                </div>
              </div>

              <!-- <div class="col-sm-6">
              <div class="form-group">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck" formControlName="active">
                  <label class="form-check-label" for="gridCheck">
                    Status </label>
                </div>
              </div>
              </div> -->

              <div class="modal-footer justify-content-between col-sm-12">
                <button mdbBtn color="link" aria-label="Close" (click)="createModal.hide()" mdbWavesEffect
                  style="text-transform: capitalize;">Cancel</button>

                <button mdbBtn color="primary" class="relative waves-light saveBtn" mdbWavesEffect
                  *ngIf="!submitted" type="submit" [disabled]="!createForm.valid">
                  Create
                </button>

                <button mdbBtn color="primary" class="relative waves-light saveBtn" mdbWavesEffect
                  *ngIf="submitted">Wait..</button>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>