import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPipe } from './pipes/search.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SearchdatePipe } from './pipes/searchdate.pipe';



@NgModule({
  declarations: [
    SearchPipe,
    SortPipe,
    SearchdatePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SearchPipe,
    SortPipe,
    SearchdatePipe
  ]
})
export class CommonSharedModule { }
