import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthGuard } from './authguard';
import { TexteditorComponent } from './Editor/texteditor/texteditor.component';
import { MarketingcontentComponent } from './Editor/marketingcontent/marketingcontent.component';
import { MarketingdetailsComponent } from './Editor/marketingdetails/marketingdetails.component';
import { TutorialeditorComponent } from './Editor/tutorialeditor/tutorialeditor.component';
import { TutorialsComponent } from './Editor/tutorials/tutorials.component';
import { ScanhistoryComponent } from './Editor/scanhistory/scanhistory.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'user', component: UserComponent, canActivate: [AuthGuard], children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'editor', component: TexteditorComponent },
      { path: 'marketing', component: MarketingcontentComponent },
      { path: 'editor', component: TexteditorComponent },
      { path: 'tutorial', component:  TutorialsComponent},
      { path: 'teditor', component: TutorialeditorComponent },
      { path: 'scanhistory', component: ScanhistoryComponent},
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' }

    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
