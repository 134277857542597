import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MDBModalRef, ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  AllNotifications: any[] = [];
  FreeNotifications: any[] = [];
  PaidNotifications: any[] = [];
  config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    currentTotalItems: 0
  };
  dbFieldName: string;
  dbFieldType: string;
  searchText = '';
  isDesc: boolean;
  isDescText: boolean;
  createForm: FormGroup;
  p: number = 1;
  submitted: boolean = false;
  createNotificationFlagLoader: boolean = false;
  topicItems = [{ Id: 1, topicName: "Paid User Topic" }, { Id: 2, topicName: "Free User Topic" }, { Id: 3, topicName: "All User Topic" }];
  @ViewChild(ModalDirective) public createModal: ModalDirective;
  //@ViewChild('createModal', { static: false }) createModal: ModalDirective;

  constructor(private fb: FormBuilder, private sharedService: SharedService, private route: ActivatedRoute,
    private router: Router, private toastrService: ToastService) {

  }

  ngOnInit() {
    this.GetNotifications();
    this.createForms();
  }
  createForms() {
    this.createForm = this.fb.group({
      notificationTitle: ['', Validators.compose([Validators.required])],
      notificationText: ['', Validators.compose([Validators.required])],
      topicList: [null, Validators.compose([Validators.required])],
    });
  }
  showPages(page) {
    if (page === 'All') {
      this.config.itemsPerPage = this.config.currentTotalItems;
    } else {
      this.config.itemsPerPage = page;
    }
  }
  GetNotifications() {
    this.sharedService.GetActiveNotifications().subscribe((response: any) => {
      this.AllNotifications = response.AllTopics;
      this.FreeNotifications = response.FreeTopics;
      this.PaidNotifications = response.PaidTopics;
    });
  }
  SendNotification(Notification) {
    this.submitted = true;
    this.sharedService.SendNotifications(Notification).subscribe((response: any) => {
      this.toastrService.success("Notification sent successfully..!!");
      this.submitted = false;
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  onPageChange(event) {
    this.config.currentPage = event;
  }
  sort(dbField, dbType) {
    if (dbField == 'Text') {
      this.isDescText = !this.isDescText;
    }

    this.isDesc = !this.isDesc;
    this.dbFieldName = dbField;
    this.dbFieldType = dbType;
  }
  CreateNotificationModel() {
    this.createModal.show();
  }
  UpdateNotifications(notification) {
    this.submitted = true;
    this.sharedService.UpdateFCMNotification(notification).subscribe((response: any) => {
      this.toastrService.success("Notification deactivited successfully..!!");
      this.submitted = false;
      this.GetNotifications();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  onSubmit() {
    this.submitted = true;
    if (this.createForm.valid) {
      this.sharedService.CreateNotifications({
        Title: this.createForm.value.notificationTitle, Text: this.createForm.value.notificationText,
        Priority: "High", NotificationId: this.createForm.value.topicList.Id
      }).subscribe(res => {
        this.submitted = false;
        this.createModal.hide();
        this.toastrService.success('Notification Created Successfully');
        this.GetNotifications();
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
      },
        error => {
          this.toastrService.error(error.error.Message);
          this.submitted = false;
          this.createModal.hide();
        });
    }
  }
  get createFormControl() {
    return this.createForm.controls;
  }
  selectedTopicValues(selectedSearchValues) {
    //   console.log("selectedSearchValues", selectedSearchValues);
    /* if (selectedSearchValues && selectedSearchValues.searchValuesId) {
 
    } */
  }

  clearedSearchValuesSelection() {
    // console.log("clearedSearchValuesSelection");
  }
}
