<div class="container">
   
    <div class="card card-container">
        <img id="profile-img" class="profile-img-card" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" />
        <p id="profile-name" class="profile-name-card"></p>
          <form #frm="ngForm" class="form-signin">
            <input type="text" id="inputUserName" class="form-control" placeholder="Email Address" name="Email" ngModel required autofocus
            pattern="[a-zA-Z0-9_+-]+[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{1,20}$" >
            
            <input type="password" id="inputPassword" class="form-control" placeholder="Password" name="Password" ngModel required>

            <div id="remember" class="checkbox">
                <label>
                    <input type="checkbox" value="remember-me"> Remember me
                </label>
            </div>
            
            <button class="btn btn-lg btn-primary btn-block btn-signin" [disabled]="frm.invalid" type="button" *ngIf="!submitted" (click)='onSubmit(frm)'>Sign in</button>
            <button *ngIf="submitted" class="btn btn-lg btn-primary btn-block btn-signin">
                <span class="spinner-border spinner-border-sm"></span>
                Sign In
              </button>
        </form><!-- /form -->
        <a href="#" class="forgot-password">
            Forgot the password?
        </a>
    </div><!-- /card-container -->
</div><!-- /container -->