import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ConstantvariablesService } from './constantvariables.service';

@Pipe({
  name: 'searchdatepipe'
})
export class SearchdatePipe implements PipeTransform {

  constructor(private constant: ConstantvariablesService ) {
  }

  transform(items: any, filter1: any, dateRange: string[], tableName: string, config: any): any {
    //console.log(" Date Pipe Filter");
    //debugger;


    if ( dateRange === null) {
      config.totalItems = Object.keys(items).length;
      this.constant.itemnew = items;
      return items;
    }
    if (typeof dateRange !== 'undefined' && dateRange.length > 0) {
      if (!filter1) {
        config.totalItems = Object.keys(items).length;
        this.constant.itemnew = items;
        return items;
      }

      if (!Array.isArray(items)){
        config.totalItems = Object.keys(items).length;
        this.constant.itemnew = items;
        return items;
      }

      if (filter1 && Array.isArray(items)) {
        const filterKeys = Object.keys(filter1);

        const  datePipe = new DatePipe('en-US');
        const startDate = datePipe.transform(dateRange[0], 'MM/dd/yyyy');
        const endDate   = datePipe .transform(dateRange[1], 'MM/dd/yyyy');

        let myDate: any;

        items = items.filter
            (
              item => {
                // debugger;
                if (tableName === 'listing') {
                  myDate = datePipe.transform(new Date(item.UploadDate), 'MM/dd/yyyy');
                }
                if ( tableName === 'order') {
                  myDate = datePipe.transform(new Date(item.EarliestShipDate), 'MM/dd/yyyy');
                  //console.log(myDate);
                }
                if ( tableName === 'strategies') {
                  myDate = datePipe.transform(new Date(item.CreatedOn), 'MM/dd/yyyy');
                }

                // console.log(myDate,' >= ',startDate,' && ',myDate,' <= ',endDate);
                return  new Date(myDate) >= new Date(startDate) &&
                new Date(myDate) <= new Date(endDate);
                            });

      }
    }

    config.totalItems = Object.keys(items).length;
    this.constant.itemnew = items;
    //console.log(items);
    return items;
  }
}
