import { Component, OnInit, ViewChild } from '@angular/core';
import { SidenavComponent } from 'ng-uikit-pro-standard';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  currentTab = 'dashboard';
  @ViewChild('sidenav', { static: true }) sidenav: SidenavComponent
  constructor(private router: Router) { }


  ngOnInit() {
    // if (localStorage.getItem('token') == null) {
    //   this.router.navigate(['/home']);
    // }
  }
  closeSideNav() {
    var dd = this.getDeviceDimension();
    if (dd.width < 500) {
      this.sidenav.hide();
    }
  }

  goForHome(){
    this.router.navigate(['/user']);
  }

  getDeviceDimension() {
    return {
      width: $(window).width()
    };
  }

  logOut() {
    localStorage.removeItem('token');
    this.router.navigate(['/home']);
  }

  navigateTo(link) {
    this.router.navigate([`${link}`]);

  }
}