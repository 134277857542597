import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-marketingdetails',
  templateUrl: './marketingdetails.component.html',
  styleUrls: ['./marketingdetails.component.css']
})
export class MarketingdetailsComponent implements OnInit {

  res: any;  
  Title: any;  
  content: any;  

  constructor( private route: ActivatedRoute,private contentservice:SharedService) { }  

  ngOnInit() {  
    let Id = this.route.snapshot.queryParams["Id"]  
   this.GetcontentById(Id);  
  }  
  GetcontentById(Id:string)  
  {  
     this.contentservice.GetcontentById(Id).subscribe((data: any)=>{  
       this.res=data;  
       this.Title=this.res.Title  
       this.content=this.res.MarketingContent  
       console.log(this.res);  
    });  
  }  
}  