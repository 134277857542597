import { Pipe, PipeTransform } from '@angular/core';
import { ConstantvariablesService } from './constantvariables.service';

@Pipe({
  name: 'searchpipe'
})

export class SearchPipe implements PipeTransform {

  constructor(private constant: ConstantvariablesService ) {
  }

  transform(items: any, sortField: any, searchstring: string, tableName: string,config: any, defaultFilter: boolean): any {
    let word: string [];
     //console.log(" Search Filter");
     //debugger;    

    if (searchstring === '') {
          config.totalItems = Object.keys(items).length;
          this.constant.itemnew = items;
          return items;
    } else if (searchstring === undefined) {
              config.totalItems = Object.keys(items).length;
              this.constant.itemnew = items;
              return items;
    } else
    {
      if (searchstring.length < this.constant.oldsearchstring.length)
      {
        this.constant.itemnew = undefined;
      }  
      //Remove all blankspaces
      this.constant.oldsearchstring = searchstring;
      searchstring = searchstring.replace(/ +(?= )/g,'');
      word = searchstring.split(' ');
    }

    // tslint:disable-next-line:prefer-for-of
   // debugger;
    for (let i = 0; i < word.length; i++) {
      if ( word[i] !== '') {
      
      // List of Filter filed which are passed to Pipe  
      for (const key in sortField) {
          if (sortField.hasOwnProperty(key)) {
             sortField[key] = word[i];
          }
      }

        if (this.constant.itemnew  === undefined) {
            this.constant.itemnew =  this.searchWord(items, sortField, defaultFilter);
            config.totalItems = Object.keys(this.constant.itemnew).length;
        } else {
            this.constant.itemnew = this.searchWord(this.constant.itemnew, sortField, defaultFilter);
            config.totalItems = Object.keys(this.constant.itemnew).length;
        }
    }

    items = this.constant.itemnew;
     // this.constant.itemnew = undefined;
   }
    return items;
  }

  searchWord(items: any, filter: any, defaultFilter: boolean) {

    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter);
      
      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
      } else {
        items =  items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
          });
        });
      }
      return items;
    }
 }
}


