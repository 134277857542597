import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  users: any;
  config = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    currentTotalItems: 0
  };
  dbFieldName: string;
  dbFieldType: string;
  //dtOptions: DataTables.Settings = {};
  searchText = '';
  isDesc: boolean;
  isDescFirstName: boolean;
  isDescLastName: boolean;
  isDescEmail: boolean;
  p: number = 1;
  submitted: boolean=false;
  constructor(private sharedService: SharedService, private route: ActivatedRoute,
    private router: Router, private toastrService: ToastService) {

  }

  ngOnInit(): void {
    this.getUsers();

  }
  getUsers() {
    this.sharedService.GetActiveUsers().subscribe((response: any) => {
      this.users = response;
      console.log(this.users);
      this.config.totalItems = this.users.length;
    });
  }

  SendNotification(NotificationId) {
    this.submitted = true;
    this.sharedService.SendNotifications(NotificationId).subscribe((response: any) => {
      //if (response === 'true') {
        this.toastrService.success("Notification sent successfully..!!");
        this.submitted = false;
      //}
      // else {
      //   this.toastrService.error("There is a problem to send notification..!!");
      //   this.submitted = false;
      // }
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  logOut() {
    localStorage.removeItem('token');
    this.router.navigate(['/home']);
  }
  showPages(page) {
    this.config.currentPage=1;
    if (page === 'All') {
      this.config.itemsPerPage = this.config.currentTotalItems;
    } else {
      this.config.itemsPerPage = page;
    }
  }

  onPageChange(event) {
    this.config.currentPage = event;
  }
  sort(dbField, dbType) {
    if (dbField == 'FirstName') {
      this.isDescFirstName = !this.isDescFirstName;
    }
    if (dbField == 'LastName') {
      this.isDescLastName = !this.isDescLastName;
    }
    if (dbField == 'EmailID') {
      this.isDescEmail = !this.isDescEmail;
    }
    this.isDesc = !this.isDesc;
    this.dbFieldName = dbField;
    this.dbFieldType = dbType;
  }
}