import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SharedService } from './services/shared.service';

@Injectable()
export class AuthGuard implements CanActivate {
  email: string;
  token: string;
  deviceId: string;
  constructor(private router: Router, private loginService: SharedService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log(next)
    // if (Object.keys(next.queryParams).length > 0) {
    //   // let token = this.verifyToken(next.queryParams);
    //   console.log("token");
    //   console.log(token);
    //   localStorage.setItem('token', JSON.stringify(token));
    // }
    // if (!localStorage.getItem('token')) {
    //   this.router.navigate(['/login'])
    // }
    // return true;

    if (!localStorage.getItem('token') && Object.keys(next.queryParams).length === 0) {
    //   this.router.navigate(['/login']);
    //   return false;
    }
    // if(Object.keys(next.queryParams).length > 0) {
    //   return this.loginService.GetActiveUsers(next.queryParams).pipe(
    //     catchError(err => {
    //       console.log('Handling error locally and rethrowing it...', err);
    //       this.router.navigate(['/login']);
    //       return of(false);
    //     })
    //   );
    // }
    return true;
    
  }
}