<div class="container">

    <div class="col-sm-12" style="margin: 72px 0 0 0;font-size: 32px;padding: 15px 0;">
        <b>Users</b>

    </div>
    <div class="col-sm-4"></div>
    <div class="col-sm-12 card p-0">
        <div class="row">
            <div class="col-sm-3 col-lg-1 mt-4 ml-2" data-toggle="tooltip" title="Show Entries"
                style="cursor: pointer;">
                <i class="fa fa-bars  " aria-hidden="true" id="dropdownMenuButton" data-toggle="dropdown"
                    style="font-size: 20px; margin-left: 20px;"></i>
                <ul class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuButton"
                    style="font-size: 13px;font-family:'Roboto'">
                    <li><a class="dropdown-item" (click)="showPages('10')">10</a></li>
                    <li><a class="dropdown-item" (click)="showPages('25')">25</a></li>
                    <li><a class="dropdown-item" (click)="showPages('50')">50 </a></li>
                    <li><a class="dropdown-item" (click)="showPages('100')">100 </a></li>
                    <li><a class="dropdown-item" (click)="showPages('200')">200 </a></li>
                </ul>
            </div>
            <div class="col-sm-5 col-lg-5  mt-2 ">
                <div class="form-group float-label-control ">
                    <input #tableName type="text" class="form-control" name="message" [(ngModel)]="searchText"
                        style="font-size: 1rem;font-family: roboto; text-align: center;color: black;
              border-top:1px solid #fff;border-left:1px solid #fff;border-right: 1px solid #fff;border-bottom: 2px solid  #ffa500;font-size: 13px;font-family:'Roboto'" placeholder="User Name Email"
                        title="eg: Atlanta" autocomplete="off">
                </div>
            </div>
            <div class="col-sm-5 col-lg-2 mt-4" style="margin-top: 0.9rem!important;">
                <i><span style="font-size: 12px; font-style: initial; "> Total Users : </span>
                </i> &nbsp;
                &nbsp;<span style="font-size: 12px; color: #ffa500;font-weight: 600">{{config.totalItems}}</span>
            </div>
            <!-- <div class="col-sm-5 col-lg-3 mt-4" style="margin-top: 0.9rem!important;">
                <span>
                    <a class="btn float-right"
                        style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 12px;margin: 10px 0;" click="SendNotification(3)">
                        Send Notification</a>
                </span>
            </div> -->
        </div>
        <!-- <div class="col-sm-12 col-lg-6 mt-4" style="margin-top: 0.9rem!important;">
            <div class="row">
                <span>
                    <a class="btn float-right"
                        style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 12px;margin: 10px 5px;"
                        (click)="SendNotification(3)">
                        Notify All Users</a>
                </span>

                <span>
                    <a class="btn float-right"
                        style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 12px;margin: 10px 5px;"
                        (click)="SendNotification(2)">
                        Notify Free Users</a>
                </span>

                <span>
                    <a class="btn float-right"
                        style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 12px;margin: 10px 5px;"
                        (click)="SendNotification(1)">
                        Notify Paid Users</a>
                </span>
            </div>
        </div> -->
    </div>

    <div class="row mt-2 mb-5" style="margin-right: 2px; margin-left: 2px;" *ngIf="users; else NoDetailFound">
        <div class="col-sm-12" id=topToListing>
            <div class="row">
                <table class="table table-hover table-responsive" *ngIf="users.length!=0 "
                    style="background-color: white;padding: 10px;">
                    <thead class=" table-borderless" style="border-bottom: 2px solid #4a4a4a;text-align: justify;">
                        <tr style="font-size: 12px;color: gray;" *ngIf="config.totalItems">
                            <th style="width: 20%;">
                                FirstName
                                <i class="fa" [ngClass]="{'fa-sort-asc':!isDescFirstName,
                                             'fa-sort-desc':isDescFirstName }" aria-hidden="true"
                                    (click)="sort('FirstName','STRING')">
                                </i>

                            </th>
                            <th style="width: 20%">
                                LastName
                                <i class="fa" [ngClass]="{'fa-sort-asc':!isDescLastName,
                                             'fa-sort-desc':isDescLastName }" aria-hidden="true"
                                    (click)="sort('LastName','STRING')">
                                </i>
                            </th>
                            <th style="width: 30%">
                                Email
                                <i class="fa" [ngClass]="{'fa-sort-asc':!isDescEmail,
                                             'fa-sort-desc':isDescEmail }" aria-hidden="true"
                                    (click)="sort('EmailID','STRING')">
                                </i>
                            </th>
                            <th style="width: 15%">
                                ScansLeft
                            </th>
                            <th style="width: 15%">
                                User Type
                            </th>
                            <!-- <th style="width: 25%; text-align: center;">Action</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <!--table body-->
                    <tbody style="font-size: 12px;">
                        <ng-container *ngFor="let record of users 
                        | searchpipe : {FirstName:searchText,LastName:searchText,EmailID:searchText} : searchText : 'user' : config : false
          
          | paginate:config; let i = index">
                            <tr style="border-bottom: solid 1px gray;text-align: justify;">
                                <td style="width: auto">
                                    <span data-toggle="tooltip" data-placement="top" title="{{ record.FirstName}}">
                                        {{ record.FirstName}}
                                    </span>
                                </td>
                                <td *ngIf="record.LastName">
                                    <span data-toggle="tooltip" data-placement="top" title="{{ record.LastName  }}">
                                        {{ record.LastName}}
                                    </span>
                                </td>
                                <td *ngIf="record.EmailID">
                                    <span data-toggle="tooltip" data-placement="top" title="record.EmailID">
                                        {{ record.EmailID}}
                                    </span>
                                </td>
                                <td>
                                    <span data-toggle="tooltip" data-placement="top" title=" {{record.ScansLeft}}">
                                        {{record.MaxAllowedScan - record.ScanCount}}
                                    </span>
                                </td>
                                <td *ngIf="record.IsPaid===true">
                                    <span >
                                        Paid User
                                    </span>
                                </td>
                                <td *ngIf="record.IsPaid===false">
                                    <span >
                                        Free User
                                    </span>
                                </td>
                                

                                <!-- <td>

                                    <span>
                                        <a class="btn float-right"
                                            style="border: 1px solid #ccccd2;background:#e7f1f9;color: #ffa500;font-size: 12px;margin: 10px 0;"click="SendNotification()">
                                            Send Notification</a>
                                       
                                    </span>
                                </td> -->

                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div class="col-sm-12" *ngIf="config.totalItems==0">
                    <h6 style="text-align: center;">No Record Found</h6>
                </div>

                <div class="col-sm-12 text-center" style="font-family: 'Roboto'!important;font-size: 13px;">
                    <div *ngIf="users.length!=0">
                        <!-- New Pagination  -->
                        <pagination-template #p="paginationApi" [id]="config.id"
                            (pageChange)="config.currentPage = $event" style="text-align: center;">
                            <div class="custom-pagination">
                                <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
                                    <span *ngIf="!p.isFirstPage()" (click)="p.previous()">
                                    </span>
                                </div>
                                <div class="page-number" *ngFor="let page of p.pages"
                                    [class.current]="p.getCurrent() === page.value">
                                    <span (click)="p.setCurrent(page.value);"
                                        *ngIf="p.getCurrent() !== page.value">{{ page.label }}</span>
                                    <div *ngIf="p.getCurrent() === page.value">
                                        <span>{{ page.label }}</span>
                                    </div>
                                </div>
                                <div class="pagination-next" [class.disabled]="p.isLastPage()">
                                    <span *ngIf="!p.isLastPage()" (click)="p.next()"> </span>
                                </div>
                            </div>
                            <!-- New Pagination  -->
                        </pagination-template>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <ng-template #NoDetailFound>
        <p style="text-align: center;">Loading ...</p>
    </ng-template>
</div>