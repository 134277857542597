<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-light" style="background-color: #f9fafc;"
  [containerInside]="true">

  <!-- Navbar brand -->

  <mdb-navbar-brand>
    <a class="navbar-brand" >
      <img src="../../../assets/images/profit_scan_banner.png" height="100" class="d-inline-block align-top" alt="">
    </a>
  </mdb-navbar-brand>

  </mdb-navbar>
<!--/.Navbar-->