import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { Content } from 'src/app/shared/Content';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-texteditor',
  templateUrl: './texteditor.component.html',
  styleUrls: ['./texteditor.component.css']
})
export class TexteditorComponent implements OnInit {
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log: string
  @ViewChild('PageContent') PageContent: any;
  
  res: any;
  submitted: boolean = false;
  constructor(private sharedService: SharedService, private router: Router, private toastrService: ToastService) { }
  contentdata = new Content();

  ngOnInit() {
    //this.Getcontent()
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
    
    this.sharedService.contentObservabel$.subscribe(data => {
    
      if (data.Id) {
       this.contentdata=data;
      }
    });
  }
  onSubmit() {
    this.submitted = true;
    this.sharedService.AddUpdateContent(this.contentdata).subscribe((data: any) => {
      this.toastrService.success("Content saved Successfully.!!");
      this.router.navigate(['user/marketing']);
      this.submitted = false;
    },
      error => {
        this.toastrService.error(error.error.Message);
        this.submitted = false;
      });
  }
  Getcontent() {
    this.sharedService.Getcontent().subscribe((data: any) => {
      this.res = data;
      console.log(this.res);
    })
  }
  Cancel() {
    this.router.navigate(['user/marketing']);
  }

}    
