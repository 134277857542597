import { Pipe, PipeTransform } from '@angular/core';
import { AttachSession } from 'protractor/built/driverProviders';

@Pipe({
  name: 'sortpipe'
})
export class SortPipe implements PipeTransform {

  transform(items: any, dbDetail: any): any {
   
    
    items = items || [];
    //debugger;
    if( dbDetail.dbFieldName !== 'undefined')
    {
        if ( dbDetail.dbFieldType === 'STRING') {
            // tslint:disable-next-line:only-arrow-functions
            items.sort(function(a, b) {
              if (String(a[dbDetail.dbFieldName]).toLowerCase() < String(b[dbDetail.dbFieldName]).toLowerCase()) {
                  return 0 * dbDetail.ascDescSort ? 1 : -1;
              } else if ( String(a[dbDetail.dbFieldName]).toLowerCase() > String(b[dbDetail.dbFieldName]).toLowerCase()) {
                  return 1 * dbDetail.ascDescSort ? 1 : -1;
              } else {
                  return 0;
              }
            });
        } else if ( dbDetail.dbFieldType === 'NUMBER') {
            // tslint:disable-next-line:only-arrow-functions
            items.sort(function(a, b) {
              if (dbDetail.ascDescSort) {
                return a[dbDetail.dbFieldName] - b[dbDetail.dbFieldName];
              } else {
                return b[dbDetail.dbFieldName] - a[dbDetail.dbFieldName];
              }
            });
        } else if ( dbDetail.dbFieldType === 'DATE') {
            // tslint:disable-next-line:only-arrow-functions
            items.sort(function(a, b) {
              if (dbDetail.ascDescSort) {
                return new Date(String ( a[dbDetail.dbFieldName] ) ).getTime() - new Date(String ( b[dbDetail.dbFieldName] ) ).getTime();
              } else {
                return new Date(String ( b[dbDetail.dbFieldName] ) ).getTime() - new Date(String ( a[dbDetail.dbFieldName] ) ).getTime();
              }
            });
        }
    }
    return items;
  }
}
