<!-- SideNav slide-out button -->
<a (click)="sidenav.toggle()" mdbBtn color="primary" class="p-3 button-collapse d-none d-sm-block d-md-none d-block d-sm-none">
    <mdb-icon fas size="2x" icon="bars" ></mdb-icon>
  </a>
  <!--/. SideNav slide-out button -->
  
  <!-- Sidebar navigation -->
  <mdb-side-nav #sidenav class="fixed" [fixed]="true">
    <!-- Logo -->
    <li>
      <div class="logo-wrapper waves-light">
        <a (click)="goForHome()"><img style="background-color:#fdfdfd;padding-top: 13%;padding-bottom: 9%;" src="../../../assets/images/profit_scan_banner.png" class="img-fluid flex-center"></a>
      </div>
    </li>
    <!--/. Logo -->
    <!-- Side navigation links -->
    <li>
      <ul class="collapsible collapsible-accordion">
        <mdb-accordion [multiple]="false" aria-multiselectable="false">
  
          <!-- Simple link -->
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/Profile');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-home" aria-hidden="true"></i> My Profile
            </mdb-accordion-item-head>
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
  
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/dashboard');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-users" aria-hidden="true"></i> Users
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
  
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/notifications');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-envelope" aria-hidden="true"></i> Notifications
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
  
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/marketing');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-bullhorn" aria-hidden="true"></i> Marketing Content
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/tutorial');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-caret-square-o-right" aria-hidden="true"></i> Tutorials
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
          <mdb-accordion-item class="no-collase" (click)="navigateTo('user/scanhistory');closeSideNav()">
            <mdb-accordion-item-head mdbWavesEffect>
              <i class="fa fa-users" aria-hidden="true"></i> Scan History
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>

          <mdb-accordion-item class="no-collase"> 
            <mdb-accordion-item-head mdbWavesEffect (click)="logOut()" style="margin-top: 300px;">
              <i class="fa fa-unlock-alt" aria-hidden="true"></i> Logout
            </mdb-accordion-item-head> 
            <mdb-accordion-item-body></mdb-accordion-item-body>
          </mdb-accordion-item>
  
        </mdb-accordion>
      </ul>
    </li>
    <!--/. Side navigation links -->
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->
  <div class="content-right" style="margin-top: 30px;">
    <router-outlet></router-outlet>
  </div>