import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { SharedService } from './services/shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { NgxPaginationModule } from "ngx-pagination";
import { UserComponent } from './user/user.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule, InputsModule, ButtonsModule, WavesModule, CollapseModule } from 'ng-uikit-pro-standard';
import { AuthGuard } from './authguard';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from 'ng2-ckeditor';
import { TexteditorComponent } from './Editor/texteditor/texteditor.component';
import { MarketingcontentComponent } from './Editor/marketingcontent/marketingcontent.component';
import { MarketingdetailsComponent } from './Editor/marketingdetails/marketingdetails.component';
import { TutorialsComponent } from './Editor/tutorials/tutorials.component';
import { TutorialeditorComponent } from './Editor/tutorialeditor/tutorialeditor.component';
import { ScanhistoryComponent } from './Editor/scanhistory/scanhistory.component';  
import { CommonSharedModule } from './shared/commonshared.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    HeaderComponent,
    UserComponent,
    NotificationsComponent,
    TexteditorComponent,
    MarketingcontentComponent,
    MarketingdetailsComponent,
    TutorialsComponent,
    TutorialeditorComponent,
    ScanhistoryComponent   
  ],
  imports: [
    BrowserModule,
    ButtonsModule,
    WavesModule,
    CommonModule,
    CollapseModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    NgxPaginationModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(), 
    CKEditorModule
  ],
  providers: [SharedService,AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
