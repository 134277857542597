import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
declare var toastr;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoginError: boolean = false;
  submitted: boolean=false;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService) {    
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.navigate(['/home']);
  }

  onSubmit(form) {   
    
    this.loginForm.value.username = form.value.Email;
    this.loginForm.value.password = form.value.Password;

    localStorage.setItem('username',this.loginForm.value.username);
    this.submitted=true; 
    this.sharedService.checkLogin({ Email: this.loginForm.value.username, Password: this.loginForm.value.password }).subscribe((response: any) => {
        this.router.navigate(['user/dashboard']);
        
        this.isLoginError = false;
        this.submitted=false; 
    }, (err) => {
      this.isLoginError = true;
      if(err.status == 0){
        toastr.error("Server not responding. Please try again after sometime.");
        this.submitted=false; 
      }else{
        toastr.error(err.error.Message);
        this.submitted=false; 
      }
    });
  }
}
