import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { ToastService } from 'ng-uikit-pro-standard';
import { Content } from 'src/app/shared/Content';

@Component({
  selector: 'app-tutorialeditor',
  templateUrl: './tutorialeditor.component.html',
  styleUrls: ['./tutorialeditor.component.css']
})
export class TutorialeditorComponent implements OnInit {
   name = 'ng2-ckeditor';
    ckeConfig: any;
    mycontent: string;
    log: string
    @ViewChild('PageContent') PageContent: any;
    
    res: any;
    submitted: boolean = false;
    constructor(private sharedService: SharedService, private router: Router, private toastrService: ToastService) { }
    contentdata = new Content();
  
    ngOnInit() {
      //this.Getcontent()
      this.ckeConfig = {
        allowedContent: false,
        extraPlugins: 'divarea',
        forcePasteAsPlainText: true
      };
      
      this.sharedService.contentObservabel$.subscribe(data => {
      
        if (data.Id) {
         this.contentdata=data;
        }
      });
    }
    onSubmit() {
      this.submitted = true;
      this.sharedService.AddUpdateTutorial(this.contentdata).subscribe((data: any) => {
        this.toastrService.success("Tutorial saved Successfully.!!");
        this.router.navigate(['user/tutorial']);
        this.submitted = false;
      },
        error => {
          this.toastrService.error(error.error.Message);
          this.submitted = false;
        });
    }
    GetTutorial() {
      this.sharedService.GetTutorials().subscribe((data: any) => {
        this.res = data;
        console.log(this.res);
      })
    }
    Cancel() {
      this.router.navigate(['user/tutorial']);
    }
  
  }    
  