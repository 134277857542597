<form role="form" #myForm="ngForm" accept-charset="UTF-8" (ngSubmit)="onSubmit()" novalidate style="margin: 30px;"> 
    <div class="col-sm-12" style="margin: 72px 0 0 0;font-size: 32px;padding: 15px 0;">
        <b>Create Marketing Content</b>

    </div>   
    <!-- <div class="form-group">    
        <input autofocus autocomplete="none" type="text" name="UserName" placeholder="Enter Title"    
            [(ngModel)]="contentdata.Title" class="form-control" required #PageContentTitle="ngModel"    
            id="PageContentTitle">    
    </div>     -->
    <ckeditor [(ngModel)]="contentdata.Content" #PageContent="ngModel" name="PageContent" required    
        [config]="ckeConfig" debounce="500">    
    </ckeditor>    
    <div class="form-group mt-4">    
        <div class="row">    
            <div class="col-3 col-md-3 mb-3">    
                <button *ngIf="!submitted" type="submit" class="btn btn-info">Submit</button>  
                <button *ngIf="submitted" class="btn btn-info">
                    <span class="spinner-border spinner-border-sm"></span>
                    Submit
                  </button>  
                  <button type="button" (click)="Cancel()" class="btn btn-info">Cancel</button>  
            </div> 
              
        </div>    
    </div>    
</form>    